<template>
    <div>
        <template v-if="cardStatus==null">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您未开通名片功能</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/acc/cardSave')">申请开通名片</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==0">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您的名片审核中</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/acc/cardSave')">修改申请资料</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==2">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您的名片驳回审核；驳回原因：{{cardCase||'无'}}</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/acc/cardSave')">重新申请</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==1">
            <div>
                <div class="middle p-40 text-left">
                    <div class="df-jc-s-b">
                        <div class="c-2 f14 pointer"></div>
                        <el-button @click="$oucy.go('/acc/CardSave')" size="mini" type="primary">编辑名片</el-button>
                    </div>
                    <div class="df m-t-20">
                        <div><img :src="$oucy.ossUrl + detail.cardPic" class="enterpriseAvata" v-if="detail.cardPic"></div>
                        <div class=" m-l-20" style="width:100%">
                            <div class="c-1 f16 m-t-10 df-jc-s-b">
                                <div>{{detail.cardName}} <span v-if="detail.ltUserAuthOfCardUserauth && detail.ltUserAuthOfCardUserauth.userPersonalAuthenticationStatu" class="userPersonalAuthenticationStatu">已实名</span></div>
                                <div>
                                    <!-- <el-button size="mini" type="primary">申请合作</el-button> -->
                                    <!-- <el-button size="mini" type="primary">在线联系</el-button> -->
                                </div>
                            </div>
                            <div class="c-2 f14 m-t-10">{{detail.cardJob}}</div>
                            <div class="df f14 m-t-10">
                                <div class="m-r-70">人气({{detail.cardPopularity}})</div>
                                <div class="m-r-70">点赞({{detail.cardLike}})</div>
                                <div class="m-r-70">收藏({{detail.cardCollect}})</div>
                                <div class="m-r-70">转发({{detail.cardTranspond}})</div>
                            </div>
                        </div>
                    </div>
                    <div class="bold f16 c-1 m-t-20">签名介绍</div>
                    <div class="c-6 f14 m-t-20">{{detail.cardSelf}}</div>
                    <div class="df boxs c-1 f12">
                        <div class="df box f1 ">
                            <img src="@/assets/images/card/mp_phone.png">
                            <div class="m-l-10">
                                <div class="bold">联系电话</div>
                                <div class="m-t-10">{{detail.cardPhone}}</div>
                            </div>
                        </div>
                        <div class="df box f2">
                            <img src="@/assets/images/card/mp_mail.png">
                            <div class="m-l-10">
                                <div class="bold">联系邮箱</div>
                                <div class="m-t-10">{{detail.cardEmail}}</div>
                            </div>
                        </div>
                        <div class="df box f3">
                            <img src="@/assets/images/card/mp_address.png">
                            <div class="m-l-10">
                                <div class="bold">联系地址</div>
                                <div class="m-t-10">{{detail.cardAdd}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>评论 <span>({{ReturnsObject.totalElements}})</span></div>
                        <div class="commentBox m-t-20">
                            <!--                  <div class="left">
                                        <img v-if="loginUser.ltUserDetailFull" :src="$oucy.ossUrl+ loginUser.ltUserDetailFull.detailAvatar" class="pic">
                                    </div>
                                    <div class="right">
                                        <inputBox ref="inputBox" @callback="createCardPersonComment" btnText="发表评论"></inputBox>                                    
                                    </div> -->
                        </div>
                    </div>
                    <comment :parentCommentId="parentCommentId" placeholder="请输入您要回复的内容" :isShowInput="true" :list="ReturnsObject.content" @setParentCommentId="setParentCommentId" @callback="callback" :detail="detail"></comment>
                    <!--                <div class="bold f16 c-1 m-t-20">企业信息</div>
                            <div class="enterpriseBox">
                                <lineTitle text="爱蒙仕品牌家具"></lineTitle>
                                <div class="c-1 f14 m-t-10 p-l-10">爱蒙仕品牌家具有限公司是一家集研发、生产、销售和服务于一体的专业标牌生产厂家。注册资金500万人民币。主要生产塑料基材、软塑透明树脂、金属、模内复合等标牌产品，洗衣机顶盖板总成，平衡板，以及塑印、彩印、顶盖板、吸音垫等产品。公司位于长江三角洲经济快速增长、风景秀丽的太湖之畔——无锡。 公司自1984年成立至今，已经过了3次跨越式的发展。2004年至今公司投入5000多万元资金建设新的生产基地，目前已竣工并投入生产，占地面积达40000m2，厂房面积近15000m2。公司2004年的年产值达4350多万元，并且每年以平均30%的速度快速增长。目前，本公司的产品已具备国际及国内多项质</div>
                                <lineTitle text="企业相册" class="m-t-30"></lineTitle>
                                <div class="c-1 f14 m-t-10 p-l-10">
                                    <img class="m-r-10" src="@/assets/images/sofa1.png">
                                    <img class="m-r-10" src="@/assets/images/sofa2.png">
                                    <img class="m-r-10" src="@/assets/images/sofa3.png">
                                </div>
                            </div> -->
                    <div v-if="0 && ReturnsObject.content">
                        <div class="bold c-1 f16">员工名片</div>
                        <div class="m-t-20 box3s">
                            <div v-for="(item,index) in ReturnsObject.content" :key="index" @click="$oucy.go('/personageDetail?id='+item.id)" class="box3 df-ai-c">
                                <div class="df-ai-c">
                                    <div><img :src="$oucy.ossUrl + item.employeePic" class="employeePic" v-if="item.employeePic"></div>
                                    <div class="m-l-10">
                                        <div class="c-1">{{item.employeeName}}<span class="m-l-10 c-2 f14">{{item.employeeJob}}</span></div>
                                        <div class="c-2 f14 m-t-10">{{item.employeePhone}}</div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <el-button size="mini" type="primary">申请合作</el-button>
                                    </div>
                                    <div class="m-t-10">
                                        <el-button size="mini" type="primary">在线联系</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                    </el-pagination>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { cardEmployee, enterpriseinfo, ltIdentity, china, cardCategory, cardPerson, cardEnterprise, furnitureGlobalBrand, cardPersonComment, cardPersonReply } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";
import oucy from "@/util/oucyUtil";

export default {
    name: "Card",
    data() {
        return {
            type: 1,

            bannerHeight: 1000,
            allGlobalClassifyList1: [],
            allGlobalClassifyList0: [],
            value2: 4,
            // 请求对象            
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                // enterpriseAuthenticationLevel: 0, //   认证级别：0全部 1实名认证 2信守约 3品质工厂
                cardPersonSort: 0, //  排序方式：0 销量[默认], 排序1 最新, 3 收藏, 4 人气,   
                cardRegion: null, //    位置区域
                siteId: null, //    站点
                cardCategory: null, //名片分类
            },
            // 返回对象
            ReturnsObject: {},



            detail: {},
            id: null,
            brands: [],
            loginUser: {},
            // 审核状态：0待审核 1审核通过 2驳回审核
            cardStatus: null,
            cardCase: null,
            parentCommentId: null,


        }
    },
    mounted() {
        // this.id = this.$route.query.id
        // this.QueryFurnitureLibrary.cardPerson = this.id
        this.getMyCardPersonDetail()

        this.loginUser = localGet('loginUser') || '{}'
    },
    methods: {
        callback(v) {
            console.log(v)
            this.createCardPersonReply(v)
        },
        setParentCommentId(v) {
            if (this.parentCommentId == v.id) {

                this.parentCommentId = null
            } else {

                this.parentCommentId = v.id
            }
            // this.$emit('setParentCommentId', v)
            console.log('点了:', v.id)
        },
        createCardPersonReply(v) {
            cardPersonReply.createCardPersonReply({ cardPersonComment: this.parentCommentId, replyContent: v.text }).then(res => {
                this.parentCommentId = null
                this.$message('回复成功')
                this.getCardPersonCommentList()

            })
        },


        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.getCardPersonCommentList();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.getCardPersonCommentList();
        },

        getMyCardPersonDetail() {
            cardPerson.getMyCardPersonDetail({}).then(res => {
                if (res) {
                    this.cardStatus = res.cardStatus
                    this.cardCase = res.cardCase
                    this.detail = res || {}
                    this.QueryFurnitureLibrary.cardPerson = res.id
                    this.getCardPersonCommentList()
                }
            })
        },
        // 查询品牌
        getUserBrands() {
            furnitureGlobalBrand.getUserBrands({ enterpriseId: this.id }).then(res => {
                this.brands = res || []
            })
        },

        // 发布个人名片评论
        createCardPersonComment(v) {

            let cardPersonCommentAttachs = []
            if (v.imageList.length) {
                for (let vv of v.imageList) {
                    cardPersonCommentAttachs.push({ cardPersonCommentAttachUrl: vv })
                }
            }
            let CreateCardPersonComment = {
                cardPerson: this.id, //个人名片编码
                cardPersonCommentContent: v.text, //评论内容
                cardPersonCommentAttachs: v.imageList.length ? JSON.stringify(cardPersonCommentAttachs) : '[]', //评论附件
            }
            cardPersonComment.createCardPersonComment(CreateCardPersonComment).then(res => {
                this.$message({
                    type: 'success',
                    message: '评论成功!'
                });
                this.$refs.inputBox.reset()
                this.QueryFurnitureLibrary.start = 0
                this.getCardPersonCommentList()
            })
        },
        // 查询个人名片评论列表
        getCardPersonCommentList(v) {
            cardPersonComment.getCardPersonCommentList(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res
            })
        },
    }
}
</script>
<style scoped lang="less">
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
}

.left_nav .el-button--text:focus {
    color: #2090FF;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.el-collapse {
    border: none;
}

.el-collapse-item__header {
    border: none;
}

.el-collapse-item__wrap {
    margin-top: 20px;
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    margin-bottom: 20px;
    margin-left: -170px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
}

.top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}


.personal_card ul li {
    height: 250px;
}

.enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
}

.enterprise_card span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
}

.enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #F8F8F9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
}

.enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    /*margin-top: -20px;*/
}

.enterprise_card .info p+p {
    /*padding-top: 10px;*/
}

.personal_card .info {
    width: 57px;

    background: #F8F8F9;
    border-radius: 2px;

}

.personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
}

.staff_info {
    width: 88px;
}

.staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: center;
}

.contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.pagination {
    margin: 30px auto 120px;
}

/*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .el-pager {
    position: relative;
    top: 9px;
}

/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}*/

.bus_recomm {
    width: 100%;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}



.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
}




.middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
}

.info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
}

.info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
}

.goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}


.middle ul li:nth-child(3n) {
    margin-right: 0px;
}



.items {
    margin-left: 10px;
}

.item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
}

.logo {
    width: 168px;
    height: 168px;
    background: #FDFDFD;
    border: 1px solid #F3F3F3;
    border-radius: 1px;
}

.dot {
    padding-left: 30px;
    flex: 1;
}

.itemTop {
    align-items: flex-start;
}

.icon3 {}

.icon4 {
    margin-right: 5px;
}

.cover {
    width: 80px;
    margin-left: 10px;
}

.myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
}

.mybutton {
    margin: 0 10px 10px 0;
    background: #F6F7FB;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
}

.text-left {
    text-align: left;

}

.myauth {
    display: flex;
    text-align: left;
}

.myauth .myauthLift {
    font-weight: bold;
    display: block;
    width: 90px;
}

.myauth .text-left {
    flex: 1;
}

.selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
}

.el-radio-group {
    text-align: left;
}

.middle {
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
}

.enterpriseAvata {
    width: 90px;
    height: 90px;
}

.box1 {
    margin-right: 20px;
    padding: 20px;
    flex: 1;
    background: #F8F8F9;
}

.cardCode {
    width: 90px;
    height: 90px;
}

.box2s {
    display: flex;
    flex-wrap: wrap;
}

.box2 {
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #FAFAFA;
    background-color: #ffffff;
    padding-bottom: 15px;
    border: 1px solid #EAEAEA;
    text-align: center;
    width: 218px;

    &:hover {

        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
    }

    .box img {
        width: 100%;
        // height: 163px;
    }
}

.box3s {
    display: flex;
    flex-wrap: wrap;
}

.box3 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #EAEAEA;
    width: 410px;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.employeePic {
    width: 80px;
    height: 80px;
}

.boxs {
    margin-left: -4px;
    margin-right: -4px;

    .box {
        background: #F6F7F8;
        border-radius: 1px;
        padding: 10px;
        margin: 10px 4px;
    }

    .f1 {
        flex: 1;
    }

    .f2 {
        flex: 2;
    }

    .f3 {
        flex: 3;
    }
}

.enterpriseBox {
    margin-top: 20px;
    background: #F6F7F8;
    border-radius: 1px;
    padding: 30px;
}

.userPersonalAuthenticationStatu {
    background: #76CB68;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
}

.commentBox {
    display: flex;
}

.pic {
    width: 42px;
    height: 42px;
    border-radius: 42px;
}

.right {
    flex: 1;
    padding-left: 10px
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.time {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
}

.comment {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    min-height: 700px;
    text-align: center;
}
</style>